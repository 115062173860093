import 'bootstrap'

import 'jquery-match-height'
// import 'flexslider'
// import 'ekko-lightbox/dist/ekko-lightbox.css'
// import 'ekko-lightbox'
// import 'owl.carousel/dist/assets/owl.carousel.css'
// import 'owl.carousel'

// import 'smartmenus'
// import 'smooth-scroll'
import 'cookieconsent'
// import 'jarallax/dist/jarallax.min.js'
// import 'jarallax/dist/jarallax-video.min.js'
import 'cookieconsent/build/cookieconsent.min.css'

import 'bs5-lightbox'

//---------------------------------------------
// Animate on Scroll
// https://michalsnik.github.io/aos/
// https://github.com/michalsnik/aos
//---------------------------------------------
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 300, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

//---------------------------------------------
// Fontawesome
// https://fontawesome.com/search?m=free&s=solid%2Cregular%2Cbrands
//---------------------------------------------
import '@fortawesome/fontawesome-free/js/all.js';

//---------------------------------------------
// Swiper
// https://swiperjs.com/
//---------------------------------------------
import Swiper, { Navigation, Pagination, Autoplay} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
window.Swiper = Swiper;


// import 'feather-icons'
// import './js/mobileCarouselOpinions'
// import 'devbridge-autocomplete';

import './js/jquery.sticky';

import './scss/main.scss';

//---------------------------------------------
// JQuery
//---------------------------------------------
require('jquery');
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import './js/custom'
import './js/navigationbar';
// import './js/jquery.scrollup';
